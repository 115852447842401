import React, { useState } from 'react';
import times from 'lodash/times';
import { connect, useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Grid,
  Divider,
  Drawer,
  Button,
  IconButton,
  Typography
} from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router';

// import Loading from '@components/Loading';

import { useTheme } from '@mui/system';
import { Close } from '@mui/icons-material';
import { AppState, DEALS_UPDATE_RECEIPT_DEAL } from '@types';
import Disclaimer from '@components/Disclaimer';
import ScrollTop from '@components/ScrollTop';

// import SignupForm from '@pages/Blog/components/SignUpForm';
// import config from '@configFile';
// import { useGetUserData } from '@hooks/useGetUserData';
// import { useGetUserMailingLists } from '@hooks/useEmail';
import { getCurrentDealEventStrObj } from '@utils/index';
import { useDealsState } from './useDealsState';
import DealSortSelect from './components/DealSortSelect';
import DealCard from './components/DealCard';
import SkeletonDealCard from './components/SkeletonDealCard';
import SkeletonDatabaseCard from './components/SkeletonDatabaseCard';
import ReceiptDealContainer from './components/ReceiptDealContainer';
import DatabaseEndMessage from './components/DatabaseEndMessage';
import SearchFilterField from './components/SearchFilterField';
import FilterDrawer from './components/FilterDrawer';
import DatabaseSettings from './components/DatabaseSettings';
import dealPageConfig from './defaultConfig';
import StrategyChips from './components/StrategyChips';
import TestDealCard from './components/DealCard/TestDealCard';

const drawerWidth = 240;

interface DealsProps extends ReturnType<typeof mapStateToProps> {
  isEventDay?: boolean;
}

const Deals = (props: DealsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [drawerOpen, setDrawerOpen] = useState(false);
  // const { data: mailingListsData } = useGetUserMailingLists();
  // const { data: user } = useGetUserData();
  // const isAdmin = !!user?.isAdmin;
  const { dealSummaryASIN, dealSummary, isEventDay } = props;
  const funcs = useDealsState(isEventDay, dealSummaryASIN);
  const currentEventObj = getCurrentDealEventStrObj();
  const theme = useTheme();

  // const mailingLists = mailingListsData || [];
  // const isSubscribedToDailyDeals = useMemo(() => {
  //   return mailingLists.includes(config.sendGrid.mailingLists.dailyDeals);
  // }, [mailingLists]);
  // const isSubscribedToHotDeals = useMemo(() => {
  //   return mailingLists.includes(config.sendGrid.mailingLists.hotDeals);
  // }, [mailingLists]);

  const {
    sort,
    onlyCoupons,
    isFiltering,
    setIsFiltering,
    searchFilter,
    setSearchFilter,
    strategyFilter,
    setStrategyFilter,
    amount,
    dealsWithASIN,
    latestDealsLoading,
    shouldShowV2Layout,
    tagData,
    unfilteredDealsWithASIN,
    toggleOnlyCoupons,
    resetAll,
    loadMore,
    handleSortChange,
    hasFilters
  } = funcs;

  const isDarkMode = theme.palette.mode === 'dark';

  const CardComponent = shouldShowV2Layout ? TestDealCard : DealCard;
  // const CardComponent = DealCard;
  // const CardComponent = TestDealCard;
  const infiniteContainerStyles = {
    marginLeft: '-17px',
    marginRight: '-17px',
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    overflow: 'auto',
    paddingBottom: '12px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch'
  } as React.CSSProperties;
  const backgroundColor = isDarkMode ? '#212121' : '#dcdbdf';

  // dealItems.push(<SignupForm key="signup" />);

  const dealItems = React.useMemo(() => {
    return (dealsWithASIN || [])
      .map((props, i) => (
        <CardComponent
          {...props}
          index={i}
          key={`${props.title}_${props.ASIN}`}
          toggleOnlyCoupons={toggleOnlyCoupons}
          onlyCoupons={onlyCoupons}
          tag={tagData}
        />
      ))
      .slice(0, amount);
  }, [dealsWithASIN, amount, toggleOnlyCoupons, onlyCoupons, tagData]);

  // // put <Signup form in the 25th position
  // if (dealItems.length >= 24 && !isSubscribedToHotDeals) {
  //   dealItems.splice(
  //     24,
  //     0,
  //     <Box sx={emailSignUpWrapperStyles} key="signup-hot-deals">
  //       <SignupForm customPrompt="Sign up to our Hot Deals email list to make sure you don't miss any deals!" />
  //     </Box>
  //   );
  // }

  // const secondPosition = isSubscribedToHotDeals ? 24 : 49;
  // // put <Signup form in the 25th position
  // if (dealItems.length >= secondPosition && !isSubscribedToDailyDeals) {
  //   // this is 49 due to the sign up item above
  //   dealItems.splice(
  //     secondPosition,
  //     0,
  //     <Box sx={emailSignUpWrapperStyles} key="signup-daily-deals">
  //       <SignupForm
  //         customTitle="Get a round up of new deals once a day at 6am PST"
  //         mailingList={config.sendGrid.mailingLists.dailyDeals}
  //         customPrompt="Sign up to our Daily Deals email list to get a daily round up of the BEST Amazon deals"
  //       />
  //     </Box>
  //   );
  // }

  // uncomment to test the loading state
  // dealItems.unshift(<SkeletonDealCard key="test"/>);

  const renderReceiptDealContainer = () => {
    if (dealSummaryASIN) {
      return (
        <ReceiptDealContainer
          dealSummary={dealSummary}
          dealSummaryASIN={dealSummaryASIN}
          dealsWithASIN={dealsWithASIN}
          handleReceiptDealClose={() => {
            dispatch({
              type: DEALS_UPDATE_RECEIPT_DEAL,
              receiptDeal: null
            });

            history.push('/');
          }}
          tag={tagData}
        />
      );
    }

    return null;
  };

  const SkeletonComponent = shouldShowV2Layout
    ? SkeletonDatabaseCard
    : SkeletonDealCard;

  const renderLoader = () => {
    return (
      <>
        {times(
          amount === dealPageConfig.initialNumDeals
            ? dealPageConfig.initialLoadingSkeletons
            : dealPageConfig.loadingSkeletons,
          (i) => (
            <SkeletonComponent
              key={i}
              index={i}
              shouldRenderLoadingMessage={latestDealsLoading}
              targetLoadingIndex={0}
            />
          )
        )}
      </>
    );
  };

  const renderMobileFilter = () => {
    return (
      <Box
        display={{
          xs: 'block',
          md: 'none'
        }}
      >
        <Box display="flex" ml="-5px" justifyContent="space-between">
          <Button
            onClick={() => setDrawerOpen(true)}
            startIcon={<TuneIcon />}
            sx={{
              mb: 1
            }}
            variant="outlined"
            size="small"
          >
            Filter Results
          </Button>
          {hasFilters() && (
            <IconButton
              size="small"
              onClick={resetAll}
              sx={{
                ml: '8px'
              }}
            >
              <Close />
            </IconButton>
          )}
        </Box>
        {showingStr && hasFilters() ? (
          <Box mb={1}>
            <Typography variant="caption">{showingStr}</Typography>
          </Box>
        ) : null}
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Box mb="24px">
            <IconButton
              onClick={() => setDrawerOpen(false)}
              sx={{ position: 'absolute', right: 8, top: 4 }}
            >
              <Close />
            </IconButton>
          </Box>
          <FilterDrawer
            funcs={{
              ...funcs
            }}
            latestDealsLoading={latestDealsLoading}
            currentlyShowingDeals={unfilteredDealsWithASIN}
            showingStr={showingStr}
          />
        </Drawer>
      </Box>
    );
  };

  const renderPreloader = () => {
    return (
      <Box
        data-testid="preloader"
        sx={{
          backgroundColor,
          width: '-webkit-fill-available'
        }}
      >
        <Box margin="0px 16px 10px 17px" data-testid="preloader-box">
          <Box
            sx={{
              height: 'auto',
              overflow: 'hidden scroll',
              marginLeft: '-17px',
              marginRight: '-17px',
              display: 'flex',
              flexWrap: 'wrap',
              paddingBottom: '12px'
            }}
            data-testid="preloader-box-inner"
          >
            {renderLoader()}
          </Box>
        </Box>
      </Box>
    );
  };

  const renderDrawerWrapper = () => {
    return (
      <Box
        display="flex"
        sx={{
          marginLeft: '-16px',
          marginRight: '-17px'
        }}
      >
        <Box
          sx={{
            minWidth: `${drawerWidth}px`,
            maxWidth: `${drawerWidth}px`,
            paddingLeft: '0.5rem',
            display: {
              xs: 'none',
              md: 'block'
            }
          }}
        >
          <FilterDrawer
            funcs={{
              ...funcs
            }}
            latestDealsLoading={latestDealsLoading}
            currentlyShowingDeals={unfilteredDealsWithASIN}
            showingStr={showingStr}
          />
        </Box>
        {latestDealsLoading ? renderPreloader() : renderInfiniteDeals()}
      </Box>
    );
  };

  const renderInfiniteDeals = () => {
    return (
      <Box
        sx={{
          backgroundColor,
          width: '-webkit-fill-available'
          // marginLeft: '-16px',
          // marginRight: '-17px',
          // minWidth: '100%'
        }}
      >
        <Box margin="0px 16px 10px 17px">
          <InfiniteScroll
            style={infiniteContainerStyles}
            dataLength={dealItems.length} // This is important field to render the next data
            next={() => loadMore()}
            hasMore={
              (dealsWithASIN.length > amount && !latestDealsLoading) ||
              latestDealsLoading
            }
            loader={renderLoader()}
            endMessage={
              <DatabaseEndMessage
                resetAll={resetAll}
                isFiltered={hasFilters()}
              />
            }
          >
            {dealItems}
          </InfiniteScroll>
        </Box>
      </Box>
    );
  };

  const showingStr = React.useMemo(() => {
    if (
      (isFiltering || hasFilters()) &&
      dealsWithASIN?.length > 0 &&
      unfilteredDealsWithASIN?.length > 0
    ) {
      return `Showing ${dealsWithASIN?.length?.toLocaleString()} of ${unfilteredDealsWithASIN?.length?.toLocaleString()} deals...`;
    }

    if (latestDealsLoading) {
      return null;
    }

    return 'Showing all deals';
  }, [
    isFiltering,
    hasFilters,
    dealsWithASIN,
    unfilteredDealsWithASIN,
    latestDealsLoading
  ]);

  const renderSortDeals = () => {
    return (
      <Grid item xs={12} sm={6}>
        <FormControl
          fullWidth
          sx={{
            margin: '10px 4px 0px 4px !important',
            width: 'calc(100% - 8px) !important'
          }}
          size="small"
        >
          <InputLabel id="sort-deals">Sort</InputLabel>
          <DealSortSelect sort={sort} handleSortChange={handleSortChange} />
        </FormControl>
      </Grid>
    );
  };

  return (
    <>
      {renderReceiptDealContainer()}
      <Disclaimer />

      <Box
        marginLeft="-8px"
        sx={{
          mb: {
            xs: 0.5,
            sm: 1.5
          }
        }}
      >
        <Grid container>
          {renderSortDeals()}
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              alignItems: 'end',
              display: 'flex'
            }}
          >
            <SearchFilterField
              setIsFiltering={setIsFiltering}
              dealsWithASIN={dealsWithASIN}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
            />
            <DatabaseSettings />
          </Grid>
        </Grid>
      </Box>
      {currentEventObj?.shouldShowStrategyChips ? (
        <>
          <Divider
            sx={{
              display: {
                xs: 'flex',
                sm: 'none'
              },
              marginBottom: '12px'
            }}
          />
          <StrategyChips
            currentlyShowingDeals={unfilteredDealsWithASIN}
            strategyFilter={strategyFilter}
            setStrategyFilter={setStrategyFilter}
            isLoading={latestDealsLoading}
          />
        </>
      ) : null}

      {renderMobileFilter()}
      {renderDrawerWrapper()}
      <ScrollTop />
    </>
  );
};

const mapStateToProps = ({
  home: { receiptDeal, dealSummaryASIN, dealSummary }
}: AppState) => ({
  receiptDeal,
  dealSummaryASIN,
  dealSummary
});

export default connect(mapStateToProps)(Deals);
